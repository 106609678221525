// ----------------------------------------------------
mat-dialog-container {

  // @AF: these negative margins are annoying, and give idea of moving padding from dialog-container to dialog-content ...
  // Not sure if needed on every toolbar, maybe better revert to this
  // mat-toolbar.mat-dialog-title {
  mat-toolbar {
    margin: -24px -24px 0 -24px !important;
    width: unset !important; // because negative margin + width 100% would cause empty space

    // max-width: 100vw; // for full screen exceptions .. (when content is too large)
    // overflow: auto; // for too large title content (mainly on mobile) -> this or row wrap
  }

  mat-toolbar.mat-dialog-title {
    display: flex; // override "block" from "mat-dialog-title"

    .title {
      flex: 1;
      text-align: center;
    }
  }

  .mat-dialog-actions {
    justify-content: flex-end; // align dialog buttons at right, by default
    margin: 0 -24px -24px -24px; // cancel dialog-container padding
    padding: 0 24px; // prevent content (buttons) from touching edges
    background-color: var(--dialog-background-color);
    border-top: 1px solid var(--border-color); // @TODO: maybe make it optional ?
  }

  .mat-dialog-content,
  [mat-dialog-content] {
    max-height: 75vh;
  }
}

.full-screen-dialog {
  max-width: 100% !important;
  width: 100%;
  // height: 100%;

  .mat-dialog-title {
    font-size: 15px; // prevents too big title on small screen
  }

  .mat-dialog-container {
    border-radius: 0;
  }
}

.dialog-large {
  min-width: 500px;
  min-height: 500px;
}

// Autoriser les snackbars plus larges
.theme-wrapper .mat-snack-bar-container {
  max-width: 90vw;
}
